/* ManageSections.css */
.ManageSections {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.ManageSections h1 {
    text-align: center;
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.ManageSections select {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.table-container {
    margin-top: 20px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #ff1a1a;
}

p {
    font-size: 1em;
    color: #555;
}

p.error {
    color: red;
}

p.success {
    color: green;
}

@media (max-width: 768px) {
    .ManageSections {
        padding: 10px;
    }

    table th, table td {
        font-size: 0.9em;
    }

    button {
        padding: 8px 12px;
        font-size: 0.9em;
    }
}

.search-input {
    margin: 10px 0;
    padding: 8px;
    width: 100%;
    max-width: 400px;
    font-size: 16px;
}

