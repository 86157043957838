.UploadPage {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    direction: rtl; /* Set the overall direction to right-to-left */
}

h1, h2, h3 {
    color: #333;
   
}

input[type="file"], input[type="text"], select, input[type="date"], textarea {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    direction: rtl; /* Ensure inputs follow RTL direction */
    text-align: right; /* Align text inside inputs to the right */
}

button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #eaff00;
    color: #0c0c0c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.text-container, .selected-text {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    direction: rtl; /* Ensure text follows RTL direction */
    text-align: right; /* Align text to the right */
}

.selected-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the end (right) */
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #007bff;
    border-radius: 5px;
    background-color: #e7f0ff;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 300px;
    box-sizing: border-box;
}

.selected-text {
    flex-grow: 1;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    background-color: #f1f1f1;
    overflow-y: auto;
    max-height: 400px;
    width: 100%;
    box-sizing: border-box;
    direction: rtl;
    text-align: right;
}

.dropdown-button {
    margin-left: 0;
    margin-top: 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    align-self: flex-end; /* Align the button to the right */
}

.dropdown-menu {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: right; /* Align dropdown items to the right */
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
    background-color: #007bff;
    color: white;
}

.persons-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #007bff;
    border-radius: 5px;
    background-color: #e7f0ff;
    direction: rtl;
    text-align: right; /* Align persons list to the right */
}

.companys-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #007bff;
    border-radius: 5px;
    background-color: #e7f0ff;
    direction: rtl;
    text-align: right; /* Align persons list to the right */
}

.persons-container h3 {
    margin-bottom: 10px;
    color: #007bff;
}

.persons-container p {
    margin: 5px 0;
}

.companys-container h3 {
    margin-bottom: 10px;
    color: #007bff;
}

.companys-container p {
    margin: 5px 0;
}


