/* UserList.css */

.UserList {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.UserList h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.UserList ul {
    list-style-type: none;
    padding: 0;
}

.UserList li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
}

.UserList li:last-child {
    border-bottom: none;
}

.UserList p {
    margin: 0;
    font-size: 14px;
}

.UserList p strong {
    font-weight: bold;
}
