/* TopBar.css */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
}

.top-bar-logo {
    display: flex;
    align-items: center;
}

.logo {
    height: 100px; /* Adjust height as needed */
    margin-right: 15px; /* Space between the logo and title */
}

.top-bar-title {
    font-size: 24px;
    font-weight: bold;
}

.red-o {
    color: red;
}

.top-bar-user {
    font-size: 18px;
}
