.AdminLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.AdminLogin h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.AdminLogin form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.AdminLogin input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.AdminLogin input:focus {
    border-color: #007bff;
    outline: none;
}

.AdminLogin button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.AdminLogin button:hover {
    background-color: #0056b3;
}

.AdminLogin .error {
    color: #ff0000;
    margin-bottom: 15px;
    text-align: center;
}
