.App {
    font-family: Arial, sans-serif;
    text-align: center;
    padding-bottom: 60px;
}

nav {
    margin: 20px 0;
}

.welcome-message {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

nav button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
}

nav button:hover {
    background-color: #0056b3;
}

nav button.active {
    background-color: orange; /* Active button color */
    color: black;
}

.text-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    height: 400px; /* Fixed height */
    overflow-y: scroll; /* Scrollable if content overflows */
}

textarea, select {
    width: 100%;
    margin-top: 10px;
}

input[type="text"], input[type="date"], textarea {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}



ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 10px 0;
}
