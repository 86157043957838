/* SectionsTable.css */
.SectionsTable {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.SectionsTable h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.SectionsTable select {
    display: block;
    width: 100%;
    max-width: 300px;
    padding: 8px;
    margin: 0 auto 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    color: #333;
}

.SectionsTable select:focus {
    outline: none;
    border-color: #007bff;
}

.SectionsTable p {
    text-align: center;
    font-size: 16px;
    color: #ff0000;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

thead {
    background-color: #007bff;
    color: #fff;
    text-align: left;
}

thead th {
    padding: 10px;
    font-size: 14px;
}

tbody tr {
    border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
    background-color: #f1f1f1;
}

tbody td {
    padding: 10px;
    font-size: 14px;
    color: #333;
    text-align: left;
}

tbody td .scrollable-text {
    max-height: 100px;
    overflow-y: auto;
    padding: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.4;
}

.scrollable-text::-webkit-scrollbar {
    width: 6px;
}

.scrollable-text::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

table th, table td {
    text-align: center;
}

tbody tr:hover {
    background-color: #e2e6ea;
}

@media (max-width: 768px) {
    table th, table td {
        font-size: 12px;
        padding: 8px;
    }
    
    .SectionsTable select {
        width: 100%;
    }
}

.pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 16px;
    font-weight: bold;
}
